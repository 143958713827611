import { isCustomerDomain } from "app-constants";
import { IBranch } from "types/customer";
import { ICustomerAddress, ICustomerContact } from "types/customer-portal-so";
import { IDepositsItem } from "types/invoice";
import { Request } from "utils/request";

export const getListCustomerAddressApi = () => {
  return Request.call<{ data: ICustomerAddress[] }>({
    url: "/customer-portal/address",
    method: "GET",
  });
};

export const updateCustomerAddressApi = (
  payload: ICustomerAddress
) => {
  return Request.call<{ data: ICustomerAddress }>({
    url: isCustomerDomain
      ? "/customer-portal/address/update"
      : "/customer/address/update",
    method: "PUT",
    data: {
      ...payload,
    },
  });
};

export const createCustomerAddressApi = (
  payload: ICustomerAddress
) => {
  return Request.call<{ data: ICustomerAddress }>({
    url: isCustomerDomain
      ? "/customer-portal/address/create"
      : "/customer/address/create",
    method: "PUT",
    data: {
      ...payload
    },
  });
};

export const deleteCustomerAddressApi = (payload: {
  id: any;
  customer_id?: any;
}) => {
  return Request.call<{ data: ICustomerAddress }>({
    url: isCustomerDomain
      ? "/customer-portal/address/delete"
      : "/customer/address/delete",
    method: "PUT",
    data: {
      ...payload,
    },
  });
};

export const setDefaultCustomerAddressApi = (payload: {
  id: any;
  customer_id?: any;
}) => {
  return Request.call<{ data: ICustomerAddress }>({
    url: "/customer/address/set-default",
    method: "PUT",
    data: {
      ...payload,
    },
  });
};

// contact
export const getListCustomerContactApi = () => {
  return Request.call<{ data: ICustomerContact[] }>({
    url: "/customer-portal/contact/list",
    method: "PUT",
  });
};
export const updateCustomerContactApi = (
  payload: ICustomerContact
) => {
  return Request.call<{ data: ICustomerContact }>({
    url: isCustomerDomain
      ? "/customer-portal/contact/update"
      : "/customer/contact/update",
    method: "PUT",
    data: {
      ...payload,
    },
  });
};

export const createCustomerContactApi = (
  payload: ICustomerContact
) => {
  return Request.call<{ data: ICustomerContact }>({
    url: isCustomerDomain
      ? "/customer-portal/contact/create"
      : "/customer/contact/create",
    method: "PUT",
    data: {
      ...payload
    },
  });
};

export const deleteCustomerContactApi = (payload: {
  id: any;
  customer_id?: any;
}) => {
  return Request.call<{ data: ICustomerContact }>({
    url: isCustomerDomain
      ? "/customer-portal/contact/delete"
      : "/customer/contact/delete",
    method: "PUT",
    data: {
      ...payload,
    },
  });
};

// end contact

export const getCustomerPODepositListApi = (payload: { so_id: any }) => {
  return Request.call<{ data: IDepositsItem[] }>({
    url: "/customer-portal/sales-order/deposit/list/",
    method: "PUT",
    data: payload,
  });
};

export const listBranchApi = () => {
  return Request.call<{ data: IBranch[] }>({
    url: "/customer-portal/branch/list",
    method: "GET",
  });
};

export const createUpdateBranchApi = (payload: IBranch) => {
  return Request.call<{ data: IBranch }>({
    url: payload?.id
      ? "/customer-portal/branch/update"
      : "/customer-portal/branch/create",
    method: "PUT",
    data: payload,
  });
};
