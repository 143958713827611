import {
  BusinessOutlined,
  PriceChangeOutlined,
  QrCodeScannerOutlined,
  ShoppingBasketOutlined
} from "@mui/icons-material";
import { Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import { CustomerTiersEnum } from "app-constants";
import AvatarMenu from "components/CustomerPortalComponents/AvatarMenu";
import { DetailSkeleton } from "components/SkeletonComponents";
import { Suspense } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "redux-store";
import { ROUTERS_CUSTOMER } from "routers/constants";

const tabItems = [
  {
    icon: <QrCodeScannerOutlined />,
    label: "Inventory",
    link: ROUTERS_CUSTOMER.INVENTORY,
  },
  {
    icon: <ShoppingBasketOutlined />,
    label: "Order",
    link: ROUTERS_CUSTOMER.PURCHASE_ORDER,
  },
  {
    icon: <ShoppingBasketOutlined />,
    label: "Backorder",
    link: ROUTERS_CUSTOMER.PURCHASE_BACK_ORDER,
  },
  {
    icon: <PriceChangeOutlined />,
    label: "Invoice",
    link: ROUTERS_CUSTOMER.INVOICE,
  },
  {
    icon: <BusinessOutlined />,
    label: "Company",
    link: ROUTERS_CUSTOMER.PROFILE,
  },
];

export default function CustomerPrivateLayout() {
  const { user } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <Box
        component="main"
        className="MainContent"
        sx={{
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          gap: 1,
          overflow: "auto",
        }}
      >
        <Box sx={{ flex: 1, width: "100%" }}>
          <Box
            sx={{
              position: "sticky",
              top: { sm: 0 },
              bgcolor: "background.body",
              zIndex: 11,
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: "divider",
            }}
          >
            <Box sx={{ maxWidth: 1200, m: "auto" }}>
              <Box
                sx={{
                  px: { xs: 2, md: 6 },
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  my: 2,
                }}
              >
                <img src="/LOGO-Web.png" alt="Abaco" width={96} />

                <Stack>
                  <Typography
                    component="h6"
                    sx={{
                      mb: 0,
                      fontSize: {
                        xs: "1.2rem",
                        sm: "1.5rem",
                      },
                    }}
                  >
                    Abaco Portal
                  </Typography>
                  <Typography
                    level="body-sm"
                    sx={{
                      mt: 0,
                    }}
                  >
                    Your Gateway to Seamless Interaction and Effortless
                    Collaboration
                  </Typography>

                  <Typography>
                    <Typography level="body-sm" color="success">Verified ID: {user?.cus_number}</Typography> - <Typography level="body-sm" color='primary'> {user?.tier ? CustomerTiersEnum[user?.tier] : ''}</Typography>
                  </Typography>
                </Stack>

                <Box
                  sx={{
                    ml: "auto",
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {/* <ColorSchemeToggle /> */}

                  <AvatarMenu />

                </Box>
              </Box>
              <Tabs
                defaultValue={pathname}
                sx={{
                  bgcolor: "transparent",
                }}
              >
                <TabList
                  tabFlex={1}
                  size="sm"
                  disableUnderline
                  sx={{
                    px: { xs: 2, md: 6 },
                    justifyContent: {
                      xs: "space-around",
                      sm: "flex-start",
                    },
                    [`&& .${tabClasses.root}`]: {
                      fontWeight: "600",
                      flex: "initial",
                      color: "text.tertiary",
                      [`&.${tabClasses.selected}`]: {
                        bgcolor: "transparent",
                        color: "text.primary",
                        "&::after": {
                          height: "2px",
                          bgcolor: "primary.400",
                        },
                      },
                    },
                    maxWidth: {
                      xs: "100%",
                      sm: "auto",
                    },
                    overflow: "auto",
                    scrollSnapType: "x mandatory",
                    "&::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  {tabItems.map((item) => (
                    <Link to={item.link} style={{ textDecoration: "none" }}>
                      <Tab value={item.link} key={item.link}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: {
                                xs: "block",
                                sm: "none",
                              },
                            }}
                          >
                            {item.icon}
                          </Box>
                          <Box
                            sx={{
                              display: {
                                xs: "none",
                                sm: "block",
                              },
                            }}
                          >
                            {item.label}
                          </Box>
                        </Box>
                      </Tab>
                    </Link>
                  ))}
                </TabList>
              </Tabs>
            </Box>
          </Box>
          <Box sx={{ px: { xs: 2, md: 6 }, maxWidth: 1200, m: "auto" }}>
            <Suspense fallback={<Box sx={{ mt: '8px' }}><DetailSkeleton /></Box>}>
              <Outlet />
            </Suspense>
          </Box>
        </Box>
        <Box component="footer" sx={{ py: 3 }}>
          <Typography level="body-xs" textAlign="center">
            © Abaco {new Date().getFullYear()} -{" "}
            {process.env.REACT_APP_PUBLIC_VERSION}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
