import { customerApiPrefix } from "app-constants";
import {
  IProduct,
  IProductItem,
  IProductWithId,
  TProductItemList,
} from "types/product";
import { Request } from "utils/request";

export const createProductApi = (payload: IProduct) => {
  return Request.call<{ data: IProductWithId }>({
    url: "/product/create",
    method: "PUT",
    data: payload,
  });
};

export const importProductApi = (payload: { products_chunk: any[] }) => {
  return Request.call<{ data: any }>({
    url: "/product/import",
    method: "PUT",
    data: payload,
  });
};

export const importProductDimensionApi = (payload: { data: any[] }) => {
  return Request.call<{ data: any }>({
    url: "/product/import-dimension",
    method: "PUT",
    data: payload,
  });
};

export const listProductApi = () => {
  return Request.call<{ data: IProductWithId[] }>({
    url: "/product/list",
    method: "GET",
  });
};

export const sendPricingRequestApi = () => {
  return Request.call<{ data: any }>({
    url: "/product/pricing-request",
    method: "GET",
  });
};

export const getListProductExportApi = () => {
  return Request.call<{ data: any }>({
    url: "/product/list/export",
    method: "GET",
  });
};

export const getDetailProductApi = (payload: { id: any }) => {
  return Request.call<{ data: IProductWithId }>({
    url: "/product/detail/" + payload.id,
    method: "GET",
  });
};

export const deActiveProductApi = (payload: { id: string }) => {
  return Request.call<{ data: {} }>({
    url: "/product/deactive/",
    method: "PUT",
    data: payload,
  });
};

export const createProductItemApi = (payload: {
  id: string;
  items: IProductItem[];
  name?: string;
  itemType?: string;
  itemComp?: number;
  description?: string;
  sub_items?: any[];
  business: any[];
}) => {
  return Request.call<{ data: {} }>({
    url: "/product/update/",
    method: "PUT",
    data: payload,
  });
};

export const getProductItemsApi = (payload: { wh_id?: any }) => {
  const { wh_id } = payload;
  return Request.call<{ data: TProductItemList }>({
    url:
      customerApiPrefix + "/product/items" + (wh_id ? `?wh_id=${wh_id}` : ""),
    method: "GET",
  });
};

export const getItemInStockApi = (payload: { item_id: any; so_id?: any }) => {
  return Request.call<{ data: { count: number; itemsBom: any[] } }>({
    url: customerApiPrefix + "/product/items/instock/",
    method: "PUT",
    data: payload,
  });
};

// factory sub items

export const getListFactorySubItemApi = (payload: { code: any }) => {
  return Request.call<{ data: any }>({
    url: "/factory-subitem/list",
    method: "PUT",
    data: payload,
  });
};

export const importFactorySubItemApi = (payload: { sub_items: any[] }) => {
  return Request.call<{ data: any }>({
    url: "/factory-subitem/import",
    method: "PUT",
    data: payload,
  });
};

export const updateFactorySubItemApi = (payload: {
  code: any;
  job: number[];
}) => {
  return Request.call<{ data: any }>({
    url: "/factory-subitem/update",
    method: "PUT",
    data: payload,
  });
};
