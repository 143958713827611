import { customerApiPrefix } from "app-constants";
import { IInvoice, IPaymentItem } from "types/invoice";
import { Request } from "utils/request";

export const listInvoiceApi = (payload: {
  items?: string[];
  customers?: any[];
  statuses?: string[];
  textSearch?: string;
  is_full?: boolean;
  due_date?: {
    from?: string;
    to?: string;
  };
}) => {
  return Request.call<{ data: IInvoice[] }>({
    url: customerApiPrefix + "/sales-order/list/invoice",
    method: "PUT",
    data: payload,
  });
};

export const getDetailInvoiceApi = (payload: { id: any }) => {
  return Request.call<{ data: IInvoice }>({
    url: customerApiPrefix + "/sales-order/list/invoice/" + payload.id,
    method: "GET",
  });
};

export const createInvoicePaymentApi = (payload: IPaymentItem) => {
  return Request.call<{ data: IPaymentItem }>({
    url: "/sales-order/invoice/payment/create/",
    method: "PUT",
    data: payload,
  });
};

export const remindInvoicePaymentApi = (payload: { invoice_id: any }) => {
  return Request.call<{ data: IInvoice }>({
    url: "/sales-order/invoice/payment/reminder/",
    method: "PUT",
    data: payload,
  });
};

export const syncInvoiceQBOApi = (payload: { so_id: any }) => {
  return Request.call<{ data: IInvoice }>({
    url: "/sales-order/invoice/sync-qbo",
    method: "PUT",
    data: payload,
  });
};

export const syncPaymentQBOApi = (payload: { so_id: any }) => {
  return Request.call<{ data: IInvoice }>({
    url: "/sales-order/invoice/sync-payment-qbo",
    method: "PUT",
    data: payload,
  });
};


export const unLockOverdueApi = (payload: { invoice_id: any }) => {
  return Request.call<{ data: IInvoice }>({
    url: "/sales-order/invoice/lock",
    method: "PUT",
    data: payload,
  });
};
