import { Box, Button, FormControl, Typography } from "@mui/joy";
import { ReactNode, useCallback, useEffect, useState } from "react";
import BaseInput from "./BaseInput";
import BaseModal from "./BaseModal";

interface IConfirmProps {
  title: string;
  description?: React.ReactNode | string;
  cancelText?: string;
  okText?: string;
  width?: number;
  prompt?: {
    label: string;
    required?: boolean;
  };
}
let show = null as unknown as ({
  title,
  description,
  cancelText,
  width,
  okText,
  prompt,
}: IConfirmProps) => Promise<
  | false
  | {
    promptText: string;
  }
>;

export function ConfirmWrapper() {
  const [isOpen, setIsOpen] = useState<
    | (IConfirmProps & {
      onOk: (value?: any) => void;
      onCancel: () => void;
    })
    | null
  >(null);

  const [promptText, setPromptText] = useState("");

  const showConfirmLocal = useCallback(
    ({
      title,
      description = "",
      cancelText = "Cancel",
      width = 400,
      okText = "OK",
      prompt,
    }: IConfirmProps) => {
      return new Promise<
        | false
        | {
          promptText: string;
        }
      >((resolve) => {
        setPromptText("");
        setIsOpen({
          title,
          description,
          cancelText,
          width,
          okText,
          prompt,
          onOk: (promptText) => {
            resolve({
              promptText,
            });
            setIsOpen(null);
          },
          onCancel: () => {
            resolve(false);
            setIsOpen(null);
          },
        });
      });
    },
    []
  );

  useEffect(() => {
    show = showConfirmLocal;
  }, [showConfirmLocal]);

  return (
    <>
      {!!isOpen && (
        <BaseModal
          isOpen={true}
          onClose={isOpen.onCancel}
          title={isOpen.title}
          width={isOpen.width}
          closeOnBackdropClick={false}
          actions={
            <Box sx={{
              display: "flex",
              gap: 1,
            }}>
              {!!isOpen.cancelText && <Button
                color="primary"
                variant="plain"
                onClick={isOpen.onCancel}
              >
                {isOpen.cancelText}
              </Button>}
              <Button
                onClick={() => isOpen.onOk(promptText)}
                sx={{
                  minWidth: 70,
                }}
                disabled={!!isOpen.prompt?.required && !promptText}
              >
                {isOpen.okText}
              </Button>
            </Box>
          }
        >
          <Box>
            {!!isOpen.description && (
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {isOpen.description}
              </Typography>
            )}

            {!!isOpen.prompt && (
              <FormControl sx={{ mt: 2 }}>
                <BaseInput
                  label={isOpen.prompt.label}
                  value={promptText}
                  onChange={(e) => setPromptText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && promptText) {
                      isOpen.onOk(promptText);
                    }
                  }}
                />
              </FormControl>
            )}
          </Box>
        </BaseModal>
      )}
    </>
  );
}
const ConfirmUtils = {
  show: (params: IConfirmProps) => show(params),
  sure: (title?: string, description?: string | ReactNode) =>
    show({
      title: title || "Confirm",
      description: description || "Are you sure you want to perform this action?",
    }),
};
export default ConfirmUtils;
