import _ from "lodash";

export const AUSABACO_ROUTERS = {
  CONTRACT: "/contract",
};
export const ausabacoScreensEnum = _.mapValues(
  AUSABACO_ROUTERS,
  (value, key) => key
);

const ROUTERS = {
  LOGIN: "/login",
  NOT_FOUND: "/404",

  DASHBOARD: "/dashboard",

  COMPANY_VERIFY: "/company-verify",
  QUICKBOOKAUTH: "/quickbook/auth/v2",
  UPSAUTH: "/ups/auth/v2",

  PROFILE: "/profile",

  PRODUCT: "/product",
  PRODUCT_NEW: "/product/new",
  PRODUCT_DETAIL: "/product/detail/:id",
  PRODUCT_IMPORT: "/product/import",
  PRODUCT_DIMENSIONS_IMPORT: "/product/dimensions-import",
  PRODUCT_FACTORY_SUB_ITEMS: "/product/factory/sub-items",

  WORKORDERLIST: "/work-order/list",
  WORKORDER: "/work-order/:id/:po_id",


  FACTORY_PURCHASE: "/factory-purchase",

  PURCHASE: "/purchase",
  PURCHASE_NEW: "/purchase/new",
  PURCHASE_DETAIL: "/purchase/detail/:id",

  ASSISTED_PURCHASE: "/assisted-purchase",
  ASSISTED_PURCHASE_NEW: "/assisted-purchase/new",
  ASSISTED_PURCHASE_DETAIL: "/assisted-purchase/detail/:id",

  FACTORY_TASK: "/factory-task",
  FACTORY_TASK_DETAIL: "/factory-task/:id",

  RECEIVE: "/receive",
  AUDIT: "/item-audit",
  ITEM_SCAN: "/item-scan",

  PALLET_PACKING: "/packing/pallet",
  BOX_PACKING: "/packing/box",
  RM_SERIAL: "/scan-rm-serial-69",

  MANUFACTURING: "/manufacturing",
  MANUFACTURING_DETAIL: "/manufacturing/detail/:id",

  PALLET: "/pallet",
  PALLET_DETAIL: "/pallet/detail/:id",

  BOX: "/box",

  CUSTOMER: "/customer",
  CUSTOMER_NEW: "/customer/new",
  CUSTOMER_DETAIL: "/customer/detail/:id",

  LEAD: "/lead",
  LEAD_NEW: "/lead/new",
  LEAD_DETAIL: "/lead/detail/:id",

  EMPLOYEE: "/employee",
  EMPLOYEE_NEW: "/employee/new",
  EMPLOYEE_DETAIL: "/employee/detail/:id",

  SALES_ORDER: "/sales-order",
  SALES_ORDER_NEW: "/sales-order/new",
  SALES_ORDER_DETAIL: "/sales-order/detail/:id",

  SALES_ORDER_BACKORDER: "/backorder",
  SALES_ORDER_BACKORDER_DETAIL: "/backorder/detail/:id",

  SALES_ORDER_PICK: "/pps-processing",
  SALES_ORDER_PICK_DETAIL: "/pps-processing/detail/:id",

  CONTAINER: "/container",
  CONTAINER_DETAIL: "/container/detail/:id",

  PROMOTION: "/promotion",
  PROMOTION_NEW: "/promotion/new",
  PROMOTION_DETAIL: "/promotion/detail/:id",

  INVENTORY: "/inventory",
  INVENTORY_GRID: "/inventory/grid",
  INVENTORY_DETAIL: "/inventory/item/:id",

  WAREHOUSE: "/warehouse",
  WAREHOUSE_NEW: "/warehouse/new",
  WAREHOUSE_DETAIL: "/warehouse/detail/:id",

  QUOTATION: "/quotation",
  QUOTATION_NEW: "/quotation/new",
  QUOTATION_DETAIL: "/quotation/detail/:id",

  SALES_REPORT: "/sales-report",

  INVOICE: "/invoice",
  INVOICE_DETAIL: "/invoice/detail/:id",

  RETURN: "/return",
  RETURN_NEW: "/return/new",
  RETURN_DETAIL: "/return/detail/:id",

  ACCOUNTING: "/accounting",

  REPORT: "/report",
  SETTINGS: "/settings",
  PRINT: "/print",
  SOREPORTLIST: "/print/so-report-list",
  TASKS: "/tasks",
};
export const screensEnum = _.mapValues(ROUTERS, (value, key) => key);

export const notificationBadge = {
  BACKORDER: "backorder",
};
export const ROUTERS_CUSTOMER = {
  LOGIN: "/login",
  ACTIVE: "/active",
  PROFILE: "/profile",

  INVOICE: "/invoice",
  INVOICE_DETAIL: "/invoice/detail/:id",

  PURCHASE_ORDER: "/order",
  PURCHASE_BACK_ORDER: "/back-order",

  PURCHASE_ORDER_NEW: "/order/new",

  INVENTORY: "/inventory",
};

export const customerScreensEnum = _.mapValues(
  ROUTERS_CUSTOMER,
  (value, key) => key
);

export default ROUTERS;
