import {
  CustomerPrivateRoute,
  PublicRouteOnly
} from "components/PrivateRoute";
import CustomerPrivateLayout from "layout/CustomerPrivateLayout";
import PublicLayout from "layout/PublicLayout";


import React from 'react';
import { RouteObject } from "react-router-dom";
import ROUTERS, { customerScreensEnum, ROUTERS_CUSTOMER } from "routers/constants";

const LoginPage = React.lazy(() => import("pages/customer-portal/login"));
const ActivePage = React.lazy(() => import("pages/customer-portal/active"));
const CustomerHome = React.lazy(() => import("pages/customer-portal/my-profile"));
const CustomerSalesOrderListPage = React.lazy(() => import("pages/customer-portal/customer-orders/list"));
const CustomerSalesBackorderListPage = React.lazy(() => import("pages/customer-portal/customer-orders/backorder"));
const CustomerSalesOrderNewPage = React.lazy(() => import("pages/customer-portal/customer-orders/new"));
const CustomerInventoryItemListPage = React.lazy(() => import("pages/customer-portal/customer-inventory/list"));
const InvoiceListPage = React.lazy(() => import("pages/invoice/list"));

function rootLoader(data: any) {
  console.log("rootloader", data);
  return {};
}

const customerRoutes: RouteObject[] = [
  // public routes
  {
    element: <PublicLayout />,
    errorElement: <h1>You have no permission this page</h1>,
    children: [
      {
        path: '/',
        element: (
          <PublicRouteOnly Component={LoginPage} to={ROUTERS_CUSTOMER.INVENTORY} />
        ),
      },
      {
        path: ROUTERS_CUSTOMER.LOGIN,
        element: (
          <PublicRouteOnly Component={LoginPage} to={ROUTERS_CUSTOMER.INVENTORY} />
        ),
      },
      {
        path: ROUTERS_CUSTOMER.ACTIVE,
        element: (
          <PublicRouteOnly Component={ActivePage} to={ROUTERS_CUSTOMER.INVENTORY} />
        ),
      },
      {
        path: ROUTERS.NOT_FOUND,
        element: (
          <h3>
            Not found page -{" "}
            <a href="/" target="_self">
              Click here to back Home
            </a>
          </h3>
        ),
      },
    ],
  },
  // private routes
  {
    element: <CustomerPrivateRoute Component={CustomerPrivateLayout} />,
    // errorElement: <h1>You have no permission this page</h1>,
    loader: rootLoader,
    children: [
      {
        path: ROUTERS_CUSTOMER.PROFILE,
        element: <CustomerHome />,
        id: customerScreensEnum.PROFILE,
      },
      {
        path: ROUTERS_CUSTOMER.PURCHASE_ORDER,
        element: <CustomerSalesOrderListPage isBackOrder={false} />,
        id: customerScreensEnum.PURCHASE_ORDER,
      },
      {
        path: ROUTERS_CUSTOMER.PURCHASE_BACK_ORDER,
        element: <CustomerSalesBackorderListPage />,
        id: customerScreensEnum.PURCHASE_BACK_ORDER,
      },
      {
        path: ROUTERS_CUSTOMER.PURCHASE_ORDER_NEW,
        element: <CustomerSalesOrderNewPage />,
        id: customerScreensEnum.PURCHASE_ORDER_NEW,
      },

      {
        path: ROUTERS_CUSTOMER.INVOICE,
        element: <InvoiceListPage />,
        id: customerScreensEnum.INVOICE,
      },

      {
        path: ROUTERS_CUSTOMER.INVENTORY,
        element: <CustomerInventoryItemListPage />,
        id: customerScreensEnum.INVENTORY,
      },
    ],
  },
]

export default customerRoutes;