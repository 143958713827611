import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

// Extend dayjs with necessary plugins
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Creates a dayjs instance in Los Angeles timezone
 * @param date - The date to parse (optional)
 * @param format - The format string for parsing (optional)
 * @param strict - Whether to use strict parsing (optional)
 * @returns A dayjs instance in Los Angeles timezone
 */

export const dayjsLosAngeles = (
  date?: dayjs.ConfigType,
  format?: string,
  strict?: boolean
): dayjs.Dayjs => {
  if (format) {
    return dayjs.utc(date, format, strict).tz("America/Los_Angeles");
  }
  return dayjs.utc(date).tz("America/Los_Angeles");
};

/**
 * Creates a dayjs instance in Ho Chi Minh City (Vietnam) timezone
 * @param date - The date to parse (optional)
 * @param format - The format string for parsing (optional)
 * @param strict - Whether to use strict parsing (optional)
 * @returns A dayjs instance in Ho Chi Minh City timezone
 */
export const dayjsVietnam = (
  date?: dayjs.ConfigType,
  format?: string,
  strict?: boolean
): dayjs.Dayjs => {
  if (format) {
    // Note: Corrected timezone identifier for Vietnam
    return dayjs.utc(date, format, strict).tz("Asia/Ho_Chi_Minh");
  }
  return dayjs.utc(date).tz("Asia/Ho_Chi_Minh");
};

// Default export for backward compatibility
export default dayjsLosAngeles;