import {
  IActiveFactoryTask,
  IFactorySubItem,
  IJobDetail,
  IPendingFactoryTask,
} from "types/factory-task";
import { Request } from "utils/request";

export const listActiveFactoryTaskApi = () => {
  return Request.call<{ data: IActiveFactoryTask[] }>({
    url: "/factory-task/active/list",
    method: "GET",
  });
};

export const detailActiveFactoryTaskApi = (payload: { _id: any }) => {
  return Request.call<{
    data: {
      task: IActiveFactoryTask;
      sub_items: IFactorySubItem[];
    };
  }>({
    url: "/factory-task/active/detail/" + payload._id,
    method: "GET",
  });
};

export const listPendingFactoryTaskApi = () => {
  return Request.call<{ data: IPendingFactoryTask[] }>({
    url: "/factory-task/pending/list",
    method: "GET",
  });
};

export const createFactoryTaskApi = (payload: IPendingFactoryTask) => {
  return Request.call<{ data: IPendingFactoryTask }>({
    url: "/factory-task/create",
    method: "PUT",
    data: payload,
  });
};

export const updateFactoryTaskApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/factory-task/update",
    method: "PUT",
    data: payload,
  });
};


export const deleteFactoryTaskApi = (payload: { _id: any }) => {
  return Request.call<{ data: IPendingFactoryTask }>({
    url: "/factory-task/delete",
    method: "PUT",
    data: payload,
  });
};

export const getListFactorySubItemJobApi = (payload: { task_id: any }) => {
  return Request.call<{ data: IJobDetail[] }>({
    url: "/factory-task/items/list",
    method: "PUT",
    data: payload,
  });
};

export const updateFactorySubItemJobApi = (payload: {
  c: string;
  date?: string;
  qty: number;
  job: number;
  task: any;
  note?: string;
}) => {
  return Request.call<{ data: any }>({
    url: "/factory-task/items/update",
    method: "PUT",
    data: payload,
  });
};

export const deleteFactorySubItemJobApi = (payload: {
  c: string;
  job: number;
  task: any;
  _id: any;
}) => {
  return Request.call<{ data: any }>({
    url: "/factory-task/items/delete",
    method: "PUT",
    data: payload,
  });
};
