import { HelpOutlineOutlined, LogoutOutlined } from "@mui/icons-material";
import { ListItemDecorator } from "@mui/joy";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Dropdown from "@mui/joy/Dropdown";
import ListDivider from "@mui/joy/ListDivider";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Typography from "@mui/joy/Typography";

import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from "redux-store";
import { setSession } from "redux-store/reducers/auth";

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeIcon from '@mui/icons-material/LightMode';
import BaseModal from "components/BaseModal";
import VideoPlayer from "components/RowEdit/video-player";
import { useState } from "react";

const AvatarMenu = () => {
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(setSession(null));
  };
  const clientInfo = useAppSelector((state) => state.auth.user);

  const { mode, setMode: setMaterialMode } = useMaterialColorScheme();
  const { setMode: setJoyMode } = useJoyColorScheme();
  const [isOpenVideo, setOpenVideo] = useState(false);

  return (
    <>
      <Dropdown>
        <MenuButton
          variant="plain"
          size="sm"
          sx={{ maxWidth: "32px", maxHeight: "32px", borderRadius: "9999999px" }}
        >
          <Avatar variant="outlined" />
        </MenuButton>
        <Menu
          placement="bottom-end"
          size="sm"
          sx={{
            zIndex: "99999",
            minWidth: "150px",
          }}
        >
          <MenuItem>
            <Box>
              <Typography level="title-sm" textColor="text.primary">
                {clientInfo?.contact?.name}
              </Typography>
              <Typography level="body-xs" textColor="text.tertiary">
                {clientInfo?.contact?.email}
              </Typography>
            </Box>
          </MenuItem>
          <ListDivider />
          <MenuItem
            onClick={(event) => {
              setOpenVideo(true)
            }}
          >
            <ListItemDecorator>
              <HelpOutlineOutlined color='disabled' fontSize='small' sx={{
                color: 'var(--joy-palette-text-icon)',
              }} />
            </ListItemDecorator>

            Help
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              if (mode === 'light') {
                setJoyMode('dark')
                setMaterialMode('dark')
              } else {
                setJoyMode('light')
                setMaterialMode('light')
              }
            }}
          >
            <ListItemDecorator sx={{
              color: 'var(--joy-palette-text-icon)',
            }}>
              {mode === 'dark' ? <LightModeIcon fontSize="small" /> : <DarkModeRoundedIcon fontSize="small" />}

            </ListItemDecorator>

            {mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
          </MenuItem>



          <ListDivider />
          <MenuItem onClick={handleLogout}>
            <ListItemDecorator>
              <LogoutOutlined color='secondary' fontSize='small' sx={{
                color: 'var(--joy-palette-text-icon)',
              }} />
            </ListItemDecorator>

            Log out
          </MenuItem>
        </Menu>
      </Dropdown>
      <BaseModal
        isOpen={isOpenVideo}
        onClose={() => {
          setOpenVideo(false)
        }}
        title={"Get Started Easily"}
        width={600}
      >
        <VideoPlayer autoPlay={true} videoUrl="https://pub-d4a095fe69d04b4abc4321afe760e1d1.r2.dev/abaco-portal.mp4"></VideoPlayer>
      </BaseModal>
    </>
  );
};

export default AvatarMenu;
