import { extendTheme } from "@mui/joy";
import { createTheme as materialCreateTheme } from "@mui/material/styles";
declare module "@mui/joy/styles" {
  interface Palette {
    gold: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    blue: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
  }
}

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          "50": "#fff7ed",
          "100": "#ffedd5",
          "200": "#fed7aa",
          "300": "#fdba74",
          "400": "#fb923c",
          "500": "#f97316",
          "600": "#ea580c",
          "700": "#c2410c",
          "800": "#9a3412",
          "900": "#7c2d12",
        },
        gold: {
          "50": "#fff8e1",
          "100": "#ffecb5",
          "200": "#ffe08a",
          "300": "#ffd25e",
          "400": "#ffc233",
          "500": "#ffb300", // Main color - Strong, readable yellow-gold
          "600": "#e6a100",
          "700": "#b38100",
          "800": "#806000",
          "900": "#4d3f00",
        },
        blue: {
          "50": "#e3f2fd",
          "100": "#bbdefb",
          "200": "#90caf9",
          "300": "#64b5f6",
          "400": "#42a5f5",
          "500": "#2196f3", // Main color - Vibrant and readable blue
          "600": "#1e88e5",
          "700": "#1976d2",
          "800": "#1565c0",
          "900": "#0d47a1",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          "50": "#fff7ed",
          "100": "#ffedd5",
          "200": "#fed7aa",
          "300": "#fdba74",
          "400": "#fb923c",
          "500": "#f97316",
          "600": "#ea580c",
          "700": "#c2410c",
          "800": "#9a3412",
          "900": "#7c2d12",
        },
        gold: {
          "50": "#fff8e1",
          "100": "#ffecb5",
          "200": "#ffe08a",
          "300": "#ffd25e",
          "400": "#ffc233",
          "500": "#ffb300", // Main color - Strong, readable yellow-gold
          "600": "#e6a100",
          "700": "#b38100",
          "800": "#806000",
          "900": "#4d3f00",
        },
        blue: {
          "50": "#e3f2fd",
          "100": "#bbdefb",
          "200": "#90caf9",
          "300": "#64b5f6",
          "400": "#42a5f5",
          "500": "#2196f3", // Main color - Vibrant and readable blue
          "600": "#1e88e5",
          "700": "#1976d2",
          "800": "#1565c0",
          "900": "#0d47a1",
        },
      },
    },
  },
});

export const materialTheme = materialCreateTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#f97316",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: "#f97316",
        },
      },
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--joy-palette-neutral-outlinedBorder)",
          },
        },
      },
    },
  },
});

export default theme;
