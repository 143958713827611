import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from "redux-persist";

import authReducer from "./reducers/auth";
import commonReducer from "./reducers/common";
import contractReducer from "./reducers/contract";
import customerReducer from "./reducers/customer";
import customerPortalSOReducer from "./reducers/customer-portal-so";
import employeeReducer from "./reducers/employee";
import factoryTaskReducer from "./reducers/factory-task";
import inventoryReducer from "./reducers/inventory";
import invoiceReducer from "./reducers/invoice";
import manufacturingReducer from "./reducers/manufacturing";
import productReducer from "./reducers/product";
import promotionReducer from "./reducers/promotion";
import purchaseReducer from "./reducers/purchase";
import quotationReducer from "./reducers/quotation";
import returnReducer from "./reducers/return";
import salesOrderReducer from "./reducers/sales-order";
import salesReportReducer from "./reducers/sales-report";
import settingsReducer from "./reducers/settings";
import taskReducer from "./reducers/task";
import warehouseReducer from "./reducers/warehouse";

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  product: productReducer,
  purchase: purchaseReducer,
  manufacturing: manufacturingReducer,
  customer: customerReducer,
  employee: employeeReducer,
  inventory: inventoryReducer,
  warehouse: warehouseReducer,
  salesOrder: salesOrderReducer,
  return: returnReducer,
  invoice: invoiceReducer,
  promotion: promotionReducer,
  settings: settingsReducer,
  task: taskReducer,
  quotation: quotationReducer,
  salesReport: salesReportReducer,
  contract: contractReducer,
  customerPortalSO: customerPortalSOReducer,
  factoryTask: factoryTaskReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
// apply TTypeReducer temp for read type persist
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
