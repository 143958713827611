import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isCustomerDomain } from "app-constants";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  addOldSerialItemApi,
  getBOMQtyDetailItemApi,
  getDetailInventoryItemApi,
  getDetailInventoryItemHistoryApi,
  getProgressDetailItemApi,
  listInventoryItemApi,
  reStockItemApi,
  relocateItemApi,
  removeStockItemApi,
  restoreStockItemApi,
  updateAdjustQtyApi,
  updateInventoryItemPriceApi,
  updateLostQtyApi,
} from "services/inventory.service";
import { IInventoryItem } from "types/inventory";

const TYPE = "INVENTORY";

export interface IInventoryState {
  allItems: IInventoryItem[];
  listItems: IInventoryItem[];
  listParts: IInventoryItem[];
  listFset: IInventoryItem[];
  listRset: IInventoryItem[];
  listBom: IInventoryItem[];
  totalMoney: number;
}

const initialState: IInventoryState = {
  allItems: [],
  listItems: [],
  listParts: [],
  listFset: [],
  listRset: [],
  listBom: [],
  totalMoney: 0,
};

export const getListInventoryItem = createCallApiAsyncAction(
  TYPE + "/getListInventoryItem",
  listInventoryItemApi
);

export const getDetailInventoryItem = createCallApiAsyncAction(
  TYPE + "/getDetailInventoryItem",
  getDetailInventoryItemApi
);

export const getDetailInventoryItemHistory = createCallApiAsyncAction(
  TYPE + "/getDetailInventoryItemHistory",
  getDetailInventoryItemHistoryApi
);

export const relocateItem = createCallApiAsyncAction(
  TYPE + "/relocateItem",
  relocateItemApi
);

export const removeStockItem = createCallApiAsyncAction(
  TYPE + "/removeStockItem",
  removeStockItemApi
);

export const restoreStockItem = createCallApiAsyncAction(
  TYPE + "/restoreStockItem",
  restoreStockItemApi
);

export const addOldSerialItem = createCallApiAsyncAction(
  TYPE + "/addOldSerialItem",
  addOldSerialItemApi
);

export const updateInventoryItemPrice = createCallApiAsyncAction(
  TYPE + "/updateInventoryItemPrice",
  updateInventoryItemPriceApi
);

export const reStockItem = createCallApiAsyncAction(
  TYPE + "/reStockItem",
  reStockItemApi
);

export const updateLostQty = createCallApiAsyncAction(
  TYPE + "/updateLostQty",
  updateLostQtyApi
);

export const updateAdjustQty = createCallApiAsyncAction(
  TYPE + "/updateAdjustQty",
  updateAdjustQtyApi
);

export const getProgressDetailItem = createCallApiAsyncAction(
  TYPE + "/getProgressDetailItem",
  getProgressDetailItemApi
);

export const getBOMQtyDetailItem = createCallApiAsyncAction(
  TYPE + "/getBOMQtyDetailItem",
  getBOMQtyDetailItemApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getListInventoryItem.fulfilled, (state, action) => {
      state.listItems = action.payload?.response?.apiStatus
        ? action.payload?.response?.data.items || []
        : [];

      state.listParts = action.payload?.response?.apiStatus
        ? action.payload?.response?.data.parts || []
        : [];

      state.listFset = action.payload?.response?.apiStatus
        ? action.payload?.response?.data.fSetItems || []
        : [];

      state.listRset = action.payload?.response?.apiStatus
        ? action.payload?.response?.data.rSetItems || []
        : [];

      state.listBom = action.payload?.response?.apiStatus
        ? action.payload?.response?.data.bomItems || []
        : [];

      if (action.payload?.response?.apiStatus) {
        state.allItems = [
          ...state.listItems,
          ...state.listParts,
          ...state.listFset,
          ...state.listRset,
          ...state.listBom,
        ];

        // sum money by qty * cost
        state.totalMoney = state.allItems.reduce((total: number, item) => {
          if (isCustomerDomain) {
            item.ntype = item.type === "n" ? "n" : "y";
          }
          return total + Number(item.quantity) * Number(item.cost);
        }, 0);
      }

      (state.listItems as any).finishLoading = true;
      (state.listParts as any).finishLoading = true;
      (state.listRset as any).finishLoading = true;
      (state.listFset as any).finishLoading = true;
      (state.listBom as any).finishLoading = true;
    });
  },
  reducers: {
    updateInventoryItemLocal: (state, action: PayloadAction<any>) => {
      const index = state.allItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.allItems[index] = action.payload;
      }
    },
  },
});
export const { updateInventoryItemLocal } = slice.actions;
export default slice.reducer;
