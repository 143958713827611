import { createSlice } from "@reduxjs/toolkit";
import { addListRemoteBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  createInvoicePaymentApi,
  getDetailInvoiceApi,
  listInvoiceApi,
  remindInvoicePaymentApi,
  syncInvoiceQBOApi,
  syncPaymentQBOApi,
  unLockOverdueApi,
} from "services/invoice.service";
import { IInvoice } from "types/invoice";

const TYPE = "INVOICE";

export interface IInvoiceState {
  list: IInvoice[];
  listTotalItems: number;
}

const initialState: IInvoiceState = {
  list: [],
  listTotalItems: 0,
};

export const getListInvoice = createCallApiAsyncAction(
  TYPE + "/getListInvoice",
  listInvoiceApi
);

export const getListInvoiceCustomer = createCallApiAsyncAction(
  TYPE + "/getListInvoiceCustomer",
  listInvoiceApi
);

export const getDetailInvoice = createCallApiAsyncAction(
  TYPE + "/getDetailInvoice",
  getDetailInvoiceApi
);

export const createInvoicePayment = createCallApiAsyncAction(
  TYPE + "/createInvoicePayment",
  createInvoicePaymentApi
);

export const remindInvoicePayment = createCallApiAsyncAction(
  TYPE + "/remindInvoicePayment",
  remindInvoicePaymentApi
);

export const syncInvoiceQBO = createCallApiAsyncAction(
  TYPE + "/syncInvoiceQBO",
  syncInvoiceQBOApi
);

export const syncPaymentQBO = createCallApiAsyncAction(
  TYPE + "/syncPaymentQBO",
  syncPaymentQBOApi
);

export const unLockOverdue = createCallApiAsyncAction(
  TYPE + "/unLockOverdue",
  unLockOverdueApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListRemoteBuilderCase({
      builder,
      fetchAction: getListInvoice,
      stateKey: "list",
    });
    builder.addCase(getDetailInvoice.fulfilled, (state, action) => {
      const invoice = action.payload?.response?.apiStatus
        ? action.payload?.response?.data
        : null;
      if (!invoice) return;
      const index = state.list.findIndex((item) => item.id === invoice.id);
      if (index > -1) {
        state.list[index] = invoice;
      }
    });
  },
  reducers: {},
});

export default slice.reducer;
