import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  createFactoryTaskApi,
  deleteFactorySubItemJobApi,
  deleteFactoryTaskApi,
  detailActiveFactoryTaskApi,
  getListFactorySubItemJobApi,
  listActiveFactoryTaskApi,
  listPendingFactoryTaskApi,
  updateFactorySubItemJobApi,
  updateFactoryTaskApi,
} from "services/factory-task.service";
import { IActiveFactoryTask, IPendingFactoryTask } from "types/factory-task";

const TYPE = "FACTORY_TASK";

export interface IState {
  activeList: IActiveFactoryTask[];
  pendingList: IPendingFactoryTask[];
}

const initialState: IState = {
  activeList: [],
  pendingList: [],
};

export const createFactoryTask = createCallApiAsyncAction(
  TYPE + "/createFactoryTask",
  createFactoryTaskApi
);

export const updateFactoryTask = createCallApiAsyncAction(
  TYPE + "/updateFactoryTask",
  updateFactoryTaskApi
);

export const deleteFactoryTask = createCallApiAsyncAction(
  TYPE + "/deleteFactoryTask",
  deleteFactoryTaskApi
);

export const listActiveFactoryTask = createCallApiAsyncAction(
  TYPE + "/listActiveFactoryTask",
  listActiveFactoryTaskApi
);

export const detailActiveFactoryTask = createCallApiAsyncAction(
  TYPE + "/detailActiveFactoryTask",
  detailActiveFactoryTaskApi
);

export const listPendingFactoryTask = createCallApiAsyncAction(
  TYPE + "/listPendingFactoryTask",
  listPendingFactoryTaskApi
);

export const getListFactorySubItemJob = createCallApiAsyncAction(
  TYPE + "/getListFactorySubItemJobApi",
  getListFactorySubItemJobApi
);

export const updateFactorySubItemJob = createCallApiAsyncAction(
  TYPE + "/updateFactorySubItemJobApi",
  updateFactorySubItemJobApi
);

export const deleteFactorySubItemJob = createCallApiAsyncAction(
  TYPE + "/deleteFactorySubItemJobApi",
  deleteFactorySubItemJobApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: listActiveFactoryTask,
      stateKey: "activeList",
    });

    addListBuilderCase({
      builder,
      fetchAction: listPendingFactoryTask,
      stateKey: "pendingList",
    });
  },
  reducers: {},
});

export default slice.reducer;
