import { Box, Button } from '@mui/joy';
import React, { FC, useRef, useState } from 'react';

interface VideoPlayerProps {
  videoUrl: string;
  width?: string;
  height?: string;
  autoPlay?: boolean;
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  videoUrl,
  width = '100%',
  height = 'auto',
  autoPlay = false
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(autoPlay);
  const [volume, setVolume] = useState<number>(1);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayPause = (): void => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!videoRef.current) return;

    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
  };

  return (
    <div className="video-player-container">
      <video
        ref={videoRef}
        width={width}
        height={height}
        onClick={handlePlayPause}
        onEnded={() => setIsPlaying(false)}
        autoPlay={autoPlay}
        playsInline
        preload="metadata"
        controlsList="nodownload"
        style={{
          border: "1px solid #c2d3c2"
        }}
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <Box
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Button
          size='sm'
          onClick={handlePlayPause}
          type="button"
          aria-label={isPlaying ? 'Pause' : 'Play'}
        >
          {isPlaying ? 'Pause' : 'Play'}
        </Button>

        <Box></Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label htmlFor="volume">Volume:</label>
          <input
            id="volume"
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={volume}
            onChange={handleVolumeChange}
          />
        </Box>
      </Box>
    </div>
  );
};

export default VideoPlayer