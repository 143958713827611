import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import { persistEncryptReducer } from "redux-store/redux-utils";
import {
  createProductApi,
  createProductItemApi,
  deActiveProductApi,
  getDetailProductApi,
  getItemInStockApi,
  getListFactorySubItemApi,
  getListProductExportApi,
  getProductItemsApi,
  importFactorySubItemApi,
  importProductApi,
  importProductDimensionApi,
  listProductApi,
  sendPricingRequestApi,
  updateFactorySubItemApi,
} from "services/product.service";
import { IProductWithId, TProductItemList } from "types/product";

const TYPE = "PRODUCT";

export interface IProductState {
  list: IProductWithId[];
  productItems: TProductItemList;
  mapProductItems: {
    [key: string]: TProductItemList;
  };
}

const initialState: IProductState = {
  list: [],
  productItems: [],
  mapProductItems: {},
};

export const createProduct = createCallApiAsyncAction(
  TYPE + "/createProduct",
  createProductApi
);

export const importProduct = createCallApiAsyncAction(
  TYPE + "/importProduct",
  importProductApi
);

export const importProductDimension = createCallApiAsyncAction(
  TYPE + "/importProductDimension",
  importProductDimensionApi
);

export const getListProduct = createCallApiAsyncAction(
  TYPE + "/getListProduct",
  listProductApi
);

export const sendPricingRequest = createCallApiAsyncAction(
  TYPE + "/sendPricingRequest",
  sendPricingRequestApi
);

export const getListProductExport = createCallApiAsyncAction(
  TYPE + "/getListProductExport",
  getListProductExportApi
);

export const getDetailProduct = createCallApiAsyncAction(
  TYPE + "/getDetailProduct",
  getDetailProductApi
);

export const createProductItem = createCallApiAsyncAction(
  TYPE + "/createProductItem",
  createProductItemApi
);

export const deActiveProduct = createCallApiAsyncAction(
  TYPE + "/deActiveProduct",
  deActiveProductApi
);

export const getProductItems = createCallApiAsyncAction(
  TYPE + "/getProductItems",
  getProductItemsApi
);

export const getItemInStock = createCallApiAsyncAction(
  TYPE + "/getItemInStock",
  getItemInStockApi
);

// factory sub item

export const getListFactorySubItem = createCallApiAsyncAction(
  TYPE + "/getListFactorySubItem",
  getListFactorySubItemApi
);

export const importFactorySubItem = createCallApiAsyncAction(
  TYPE + "/importFactorySubItemApi",
  importFactorySubItemApi
);

export const updateFactorySubItem = createCallApiAsyncAction(
  TYPE + "/updateFactorySubItemApi",
  updateFactorySubItemApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListProduct,
      stateKey: "list",
    });

    builder.addCase(getProductItems.fulfilled, (state: any, action: any) => {
      state.productItems = action.payload?.response?.apiStatus
        ? action.payload?.response?.data || []
        : [];
      state.productItems.finishLoading = true;
      state.mapProductItems = state.productItems?.reduce?.(
        (acc: any, item: any) => {
          acc[item.id] = item;
          return acc;
        },
        {}
      );
    });
  },
  reducers: {},
});

export default persistEncryptReducer(TYPE, slice.reducer, [
  "productItems",
]) as typeof slice.reducer;
