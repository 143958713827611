import { store } from "redux-store/index";
import { setMenuState } from "redux-store/reducers/common";



const MAIL_NHAMAY_SANXUAT_AUSABACO = 'vantinh@ausabaco.com.vn'
const MAIL_NHAMAY_GD_AUSABACO = 'tinhthanh@ausabaco.com.vn'
const MAIL_NHAMAY_KCS_AUSABACO = 'minhtri@ausabaco.com.vn'
const MAIL_NHAMAY_KYTHUAT_TRUYENTHONG_AUSABACO = 'kcs-huynhnguyen@ausabaco.com.vn'
const MAIL_NHAMAY_KYTHUAT_GYM_AUSABACO = 'thietke-tqdanh@ausabaco.com.vn'
const MAIL_NHAMAY_KYTHUAT_DIEN_AUSABACO = 'kythuat-vanduong@ausabaco.com.vn'
const MAIL_NHAMAY_MKT_AUSABACO = 'tp.mkt@ausabaco.com.vn'
const MAIL_NHAMAY_KETOANTRUONG_AUSABACO = 'ketoantruong@ausabaco.com.vn'
const MAIL_NHAMAY_KEHOACH_AUSABACO = 'groupkehoach@ausabaco.com.vn'
const MAIL_NHAMAY_TPKEHOACH_AUSABACO = 'vattu-diemhuong@ausabaco.com.vn'

export const emailsFactory = [{
  value: MAIL_NHAMAY_SANXUAT_AUSABACO,
  label: 'P. Sản xuất	- Nguyễn Văn Tỉnh'
},
{
  value: MAIL_NHAMAY_KCS_AUSABACO,
  label: 'Bp. KCS	- Nguyễn Minh Trí'
}, {
  value: MAIL_NHAMAY_KYTHUAT_TRUYENTHONG_AUSABACO,
  label: 'P. KT truyền thống -	Phan Huỳnh Nguyên'
}, {
  value: MAIL_NHAMAY_KYTHUAT_GYM_AUSABACO,
  label: 'P. KT Gym	- Trần Quốc Danh'
}, {
  value: MAIL_NHAMAY_KYTHUAT_DIEN_AUSABACO,
  label: 'Bp. KT điện -	Nguyễn Văn Dương'
}, {
  value: MAIL_NHAMAY_MKT_AUSABACO,
  label: 'P. Marketing - Trưởng Phòng'
}, {
  value: MAIL_NHAMAY_KETOANTRUONG_AUSABACO,
  label: 'P. Kế toán - Nguyễn Thị Nga'
},
{
  value: MAIL_NHAMAY_TPKEHOACH_AUSABACO,
  label: 'P. Kế hoạch - Hoàng Thị Diễm Hương'
}
]

export const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block', 'link'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'size': [] }],  // custom dropdown

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'align': [] }],

  ['clean']
];


export function openSidebar() {
  if (typeof document !== "undefined") {
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty("--SideNavigation-slideIn", "1");
    setTimeout(() => {
      store.dispatch(setMenuState(true));
    }, 500);
  }
}

export function closeSidebar() {
  if (typeof document !== "undefined") {
    document.documentElement.style.removeProperty("--SideNavigation-slideIn");
    document.body.style.removeProperty("overflow");
    setTimeout(() => {
      store.dispatch(setMenuState(false));
    }, 500);
  }
}

export function toggleSidebar() {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--SideNavigation-slideIn");

    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

export function isMobile() {
  if (typeof window !== "undefined") {
    return window.innerWidth <= 600;
  }
  return false;
}

export function isAusa() {
  if (typeof window !== "undefined") {
    const ausa = "ausavina.com";
    return window.location.hostname.includes(ausa);
  }
  return false;
}
